import Logo from '../assets/logo.png'

const socialLinks = [
	{ href: "https://www.instagram.com/dosproduccionesmusic/", className: "insta", ariaLabel: "Instagram" },
	{ href: "https://api.whatsapp.com/send?phone=541131495030", className: "whatsapp", ariaLabel: "WhatsApp" },
	{ href: "https://www.youtube.com/@DosProduccionesmusic", className: "youtube", ariaLabel: "YouTube" }
  ];
  
function Footer() {
  return (
    <footer className="">
			<div className="container">
				<div className="footer-cont">
					<div className="logo">
						<a href="/">
						<img className="logo" src={Logo} alt="Logo" />

						</a>
					</div>
					<div className="footer-nav">
					</div>
					<div className="social">
						<ul>
							{socialLinks.map((link, index) => (
								<li key={index}>
								<a href={link.href} target="_blank" rel="noopener noreferrer" aria-label={link.ariaLabel}>
									<span className={link.className}></span>
								</a>
								</li>
							))}
						</ul>
					</div>

					<div className="footer_bot">
					<span>&copy; {new Date().getFullYear()} Dos Producciones Music</span>
					</div>
				</div>
			</div>
		</footer>
  );
}

export default Footer;
