import React, {useEffect, useMemo, useCallback} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import './css/animation.css'
import './css/fonts.css'
import './css/main.css'
import './css/responsive.css'
import {useGetAccessTokenQuery, useLazyGetUserPlaylistsQuery} from './redux/reducer'
import MM1 from './assets/1.png'
import MM2 from './assets/2.png'
import MM3 from './assets/3.png'
function App() {
  const { data: accessToken, isLoading: isLoadingAccessToken } = useGetAccessTokenQuery();
  const [getUserPlaylists, { data: playlists, isLoading: isLoadingPlaylist }] = useLazyGetUserPlaylistsQuery();

  useEffect(() => {
    if (accessToken) {
      getUserPlaylists('94eluna');
    }
  }, [accessToken, getUserPlaylists]);
  const featuredPlaylistIds = useMemo(() => [
    '4Wt5FoAzdGxMqctcojZ9X9',
    '4yoAhLeX0vqJ9y1FE3OSKt',
    '4KYbytAZTLhxhxHhLfOoh8',
    '0INLENe1TnfAr6v5Eq6PBq',
    '5hDmLaKEO6cfbXpELnU52n',
    '3rA8uBHp9eS7NZmGJ8bRZC'
  ], []);
  const renderCards = useCallback(() => {
    return <span className="home_cards">
      {playlists && playlists.items.filter(p => featuredPlaylistIds.includes(p.id)).map(playlist => (
         <div key={playlist.id} className="hm_card opacity_anim">
         <div className="box">
           <img src={playlist.images[0].url} alt={playlist.name} />
         </div>
         <div className="box_details">
           <div className="play-icon">
           <a href={playlist.external_urls.spotify} target='_blank' rel="noopener noreferrer" aria-label={`Play ${playlist.name}`}>
            <svg id="album-play" viewBox="0 0 25.4 25.4" aria-hidden="true">
                 <path fill="#ffffff" d="M12.7,25.4A12.7,12.7,0,1,1,25.4,12.7,12.71,12.71,0,0,1,12.7,25.4Zm0-24A11.3,11.3,0,1,0,24,12.7,11.31,11.31,0,0,0,12.7,1.4Z"></path>
                 <path fill="#ffffff" d="M11,16.47,17,13a.34.34,0,0,0,0-.59L11,8.93a.34.34,0,0,0-.51.29v7A.34.34,0,0,0,11,16.47Z"></path>
                 <path fill="#ffffff" d="M12.7,25.4A12.7,12.7,0,1,1,25.4,12.7,12.71,12.71,0,0,1,12.7,25.4Zm0-24A11.3,11.3,0,1,0,24,12.7,11.31,11.31,0,0,0,12.7,1.4Z"></path>
                 <path fill="#ffffff" d="M11,16.47,17,13a.34.34,0,0,0,0-.59L11,8.93a.34.34,0,0,0-.51.29v7A.34.34,0,0,0,11,16.47Z"></path>
               </svg>
             </a>
           </div>
           <h2>{playlist.name}</h2>
         </div>
       </div>
      ))}
    </span>
  }, [playlists, featuredPlaylistIds]);
  const renderBestArtists = useCallback(() => {
    return (
      <div className="ll_row">
        <span >
          <div className="ll_card">
              <span className="txt">
                  <h5>#1 Artista más escuchado</h5>
                  <h2>Tomy DJ</h2>
                  <a href="https://open.spotify.com/artist/6e7knXUrhWcp85TTN6za9z" target='_blank'rel="noreferrer">ESCUCHAR AHORA</a>
              </span>
              <span className="img">
                  <img src="https://i.scdn.co/image/ab676161000051743983f16f94f2b7545345e0c2" alt="Tomy DJ"/>
              </span>
              <span className="bg_filt"></span>
          </div>
        </span>
        <span>
          <div className="ll_card">
              <span className="txt">
                  <h5>#2 Artista más escuchado</h5>
                  <h2>Martin Moreno</h2>
                  <a href="https://open.spotify.com/artist/59h2d6LEgTjC8LUySW54EA" target='_blank' rel="noreferrer">ESCUCHAR AHORA</a>
              </span>
              <span className="img">
                  <img src="https://i.scdn.co/image/ab67616100005174d9668bef7a32b57eab5f973c" alt="Martin Moreno"/>
              </span>
              <span className="bg_filt"></span>
          </div>
        </span>
      </div>
    )
  }, []);
  if (isLoadingAccessToken || isLoadingPlaylist) {
    return <div >
        <div className="hm_gradient-1"></div>
        <div className="hm_gradient-2"></div>
        <div className='loading-container'>
         <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24"><path fill="white" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity="0.25"/><path fill="white" d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"><animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/></path></svg>
        </div>
    </div>;
  }
  return (
    <div>
      <Header />
      <div className="home_main">
        <div className="hm_gradient-1"></div>
        <div className="hm_gradient-2"></div>
        <div className="container hm_cont">
          <span className="home_text">
            <h1 >Nuestras Playlists</h1>
            <h3>Promocioná tu música en nuestras playlists</h3>
            <a className="green_btn" role="button" href="https://open.spotify.com/user/94eluna/playlists" target='_blank' rel="noopener noreferrer">Ver todas</a>
            </span>
          {renderCards()}
        </div>
        <div className="container">
            <div className="arrow-scroll-down slide-top">
              <a href="#last_listen" aria-label="Scroll to last listened">
                <svg id="arrow-scroll-down" viewBox="0 0 32 32" aria-hidden="true">
                  <polygon fill="#ffffff" points="15.98 22.43 7.28 13.73 8.7 12.32 15.98 19.6 23.28 12.3 24.7 13.72 15.98 22.43"></polygon>
                  <path fill="#ffffff" d="M16,32A16,16,0,1,1,32,16,16,16,0,0,1,16,32ZM16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Z"></path>
                </svg>
              </a>
            </div>
        </div>
      </div>
      <div id="last_listen" className="last_listen">
        <div className="container">
            <h1>Nuestros artistas más escuchados</h1>
              {renderBestArtists()}
        </div>
      </div>
		  <div className="home_links">
        <div className="container">
          <h1 className="title-1">Marketing musical</h1>
        </div>
        <div className="container links">
          <div className="web_link">
            <img src={MM1} alt='Crecer tu música' />
            <h3 className="subtitle-1">Hace crecer tu música</h3>
            {/* <p className="parag-1">Edit your profile, change your password and update your payment informations.</p> */}
            <a className="a_link" href="https://api.whatsapp.com/send?phone=541131495030" target='_blank'rel="noreferrer">CONTACTAR</a>
          </div>
          <div className="web_link">
            <img src={MM2} alt='Suma seguidores' />
            <h3 className="subtitle-1">Sumá seguidores en todas las redes</h3>
            {/* <p className="parag-1">Listen to your favorite music without any problems. Download the Spotify app to your computer.</p> */}
            <a className="a_link" href="https://api.whatsapp.com/send?phone=541131495030" target='_blank'rel="noreferrer">CONTACTAR</a>
          </div>
          <div className="web_link">
            <img src={MM3} alt='10.000 artistas' />
            <h3 className="subtitle-1">Más de 10.000 artistas crecieron con nosotros</h3>
            {/* <p className="parag-1">To play and share music inside the browser without the app <a className="a_link" href="">play.spotify.com</a> Go to.</p> */}
            <a className="a_link" href="https://api.whatsapp.com/send?phone=541131495030" target='_blank'rel="noreferrer">CONTACTAR</a>
          </div>
        </div>
		  </div>
      <Footer />
      <div id="opacity" className="opacity"></div>
    </div>
  );
}

export default App;
